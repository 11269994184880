import * as React from 'react'
import { generatePath } from 'react-router-dom'
import { routes } from '@constants/routes'
import { LayoutTypeId } from '@api/endpoints/buildings/layoutTypes'
import { NavTabItem, NavTabs } from '@controls/NavTabs'
import { useLayoutTypeFormContext } from '../LayoutTypeFormContext'

// TODO: Add translation
const tabItems = (layoutTypeId: LayoutTypeId): ReadonlyArray<NavTabItem> => [
  {
    label: 'Light Images',
    to: generatePath(routes.Admin.LayoutTypesEditLightImages, { layoutTypeId })
  },
  {
    label: 'Dark Images',
    to: generatePath(routes.Admin.LayoutTypesEditDarkImages, { layoutTypeId })
  },
  {
    label: 'Free options',
    to: generatePath(routes.Admin.LayoutTypesEditFreeOptions, { layoutTypeId })
  },
  {
    label: 'Options',
    to: generatePath(routes.Admin.LayoutTypesEditOptions, { layoutTypeId })
  },
  {
    label: 'Styles',
    to: generatePath(routes.Admin.LayoutTypesEditStyles, { layoutTypeId })
  }
]

export const LayoutTypeFormTabs: React.FC = () => {
  const { layoutType } = useLayoutTypeFormContext()

  return <NavTabs tabs={tabItems(layoutType.id)} />
}
