/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useApiQuery } from '@digital-magic/react-common/lib/api/hooks'
import { apiBaseUrl } from '@constants/configuration'
import { ApiQueryOpts } from '@api/types'
import { receiveOnly } from '@api/utils'
import { BuildingTypeList } from './types'

const buildingTypeUrl = `${apiBaseUrl}/buildingTypes`

export const queryKeys = {
  getBuildingTypesKey: ['getBuildingTypes']
}

export const useGetBuildingTypes = (opts?: ApiQueryOpts<BuildingTypeList>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: buildingTypeUrl,
        responseSchema: BuildingTypeList
      }),
    queryKey: queryKeys.getBuildingTypesKey,
    ...opts
  })
