import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, generatePath, useNavigate, useParams } from 'react-router-dom'
import * as z from 'zod'
import { isApiError } from '@digital-magic/react-common/lib/api'
import { hasValue, isEmpty } from '@digital-magic/ts-common-utils'
import { routes } from '@constants/routes'
import { RequestErrorHandler } from '@api/types'
import { IdObject } from '@api/endpoints/types'
import { isDeletableEntity } from '@api/endpoints/utils'
import { BuildingType, BuildingTypeCode, useGetBuildingTypes } from '@api/endpoints/buildings/buildingTypes'
import {
  CreateLayoutTypeRequest,
  LayoutTypeId,
  useCloneLayoutType,
  useDeleteLayoutType,
  useUpdateLayoutType
} from '@api/endpoints/buildings/layoutTypes'
import { useCreateLayoutType, useGetLayoutType } from '@api/endpoints/buildings/layoutTypes/api'
import { FileId, useFileUpload, useFilesDownload } from '@api/endpoints/files'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { useSnackbar } from 'notistack'
import { Delete } from '@mui/icons-material'
import { Alert, Box } from '@mui/material'
import { Button } from '@controls/Button'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { FormCheckbox1, FormTextFieldStyled, FormTranslatedTextField, useFormZod } from '@controls/Form'
import { Link } from '@controls/Link'
import { MenuItemEntry } from '@controls/types'
import { LayoutTypeFormStyled } from './LayoutTypeForm.styles'
import { LayoutTypeFormContext } from './LayoutTypeFormContext'
import { LayoutTypeFormTabs } from './LayoutTypeFormTabs'

type Params = {
  layoutTypeId: LayoutTypeId
}

const LayoutTypeFormValues = CreateLayoutTypeRequest.extend({
  htmlContent: z.instanceof(FileList).optional()
})
type LayoutTypeFormValues = z.infer<typeof LayoutTypeFormValues>

export const LayoutTypeForm = (): React.ReactElement => {
  const { t, i18n } = useTranslation()
  const [htmlContentRemoved, setHtmlContentRemoved] = React.useState(false)
  const snackbar = useSnackbar()
  const params = useParams<Params>()
  const navigate = useNavigate()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const uploadFile = useFileUpload({ onError: defaultErrorHandler })

  const onSubmit = async ({ htmlContent, ...values }: LayoutTypeFormValues): Promise<void> => {
    const htmlContentFile = htmlContent?.[0]

    // eslint-disable-next-line functional/no-let
    let htmlContentFileId: FileId | undefined = undefined

    if (!htmlContentRemoved && hasValue(htmlContentFile)) {
      const { id } = await uploadFile.mutateAsync(htmlContentFile)
      htmlContentFileId = id
    }

    if (hasValue(params.layoutTypeId)) {
      if (htmlContentRemoved) {
        htmlContentFileId = undefined
      } else if (isEmpty(htmlContentFile)) {
        htmlContentFileId = getLayoutType.data?.htmlContent
      }
      updateLayoutType.mutate({ id: params.layoutTypeId, ...values, htmlContent: htmlContentFileId })
    } else {
      createLayoutType.mutate({ ...values, htmlContent: htmlContentFileId })
    }
  }

  const form = useFormZod({
    schema: LayoutTypeFormValues
  })

  const onLayoutCreateSuccess = ({ id }: IdObject): void => {
    navigate(generatePath(routes.Admin.LayoutTypesEdit, { layoutTypeId: id }), { replace: true })
  }

  const onUpdateLayoutTypeSuccess = (): void => {
    void getLayoutType.refetch()
  }

  const onDeleteLayoutTypeSuccess = (): void => {
    navigate(routes.Admin.LayoutTypes)
  }

  const onDeleteLayoutTypeError: RequestErrorHandler = (e) => {
    if (isApiError(e) && e.code === 'ConstraintViolationError') {
      snackbar.enqueueSnackbar(t('pages.admin.layout_type.errors.delete_constraint'))
    } else {
      defaultErrorHandler(e)
    }
  }

  const getLayoutType = useGetLayoutType(params.layoutTypeId ?? '', {
    onError: defaultErrorHandler,
    enabled: hasValue(params.layoutTypeId)
  })

  const getBuildingTypes = useGetBuildingTypes({ onError: defaultErrorHandler })

  const createLayoutType = useCreateLayoutType({ onError: defaultErrorHandler, onSuccess: onLayoutCreateSuccess })
  const cloneLayoutType = useCloneLayoutType({ onError: defaultErrorHandler, onSuccess: onLayoutCreateSuccess })
  const updateLayoutType = useUpdateLayoutType({ onError: defaultErrorHandler, onSuccess: onUpdateLayoutTypeSuccess })
  const deleteLayoutType = useDeleteLayoutType({
    onError: onDeleteLayoutTypeError,
    onSuccess: onDeleteLayoutTypeSuccess
  })

  const isLoading =
    (hasValue(params.layoutTypeId) && getLayoutType.isFetching) ||
    getBuildingTypes.isFetching ||
    createLayoutType.isLoading ||
    updateLayoutType.isLoading ||
    deleteLayoutType.isLoading

  const htmlContent = htmlContentRemoved ? undefined : getLayoutType.data?.htmlContent
  const download3DFile = useFilesDownload(hasValue(htmlContent) ? [htmlContent] : [], { onError: defaultErrorHandler })

  const buildingTypeOptions: ReadonlyArray<MenuItemEntry<BuildingTypeCode>> = React.useMemo(
    () =>
      getBuildingTypes.data?.map((b) => ({
        label: t(`enums.building_type.${b.code}`),
        value: b.code
      })) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getBuildingTypes.data, i18n.language]
  )

  const htmlFileUrls: ReadonlyArray<string> = React.useMemo(
    // eslint-disable-next-line functional/prefer-tacit
    () => download3DFile.data?.map((f) => URL.createObjectURL(f)) ?? [],
    [download3DFile.data]
  )

  const buildingType = form.watch('buildingType')

  const selectedBuildingType = React.useMemo(
    (): BuildingType | undefined => getBuildingTypes.data?.find((b) => b.code === buildingType),
    [buildingType, getBuildingTypes.data]
  )

  React.useEffect(() => {
    if (!selectedBuildingType?.hasApartments) {
      form.setValue('apartmentsCount', undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuildingType])

  React.useEffect(() => {
    const layoutType = getLayoutType.data

    if (layoutType) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { htmlContent, ...restLayoutType } = layoutType

      form.reset({ ...restLayoutType, buildingType: layoutType.buildingType.code })
      setHtmlContentRemoved(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLayoutType.data])

  const isFormDisabled = React.useMemo(
    () => false /*hasValue(params.layoutTypeId) && !isEditableEntity(getLayoutType.data?.allowedActions)*/,
    [
      /*params.layoutTypeId, getLayoutType.data*/
    ]
  )
  const isDeleteDisabled = React.useMemo(
    () => hasValue(params.layoutTypeId) && !isDeletableEntity(getLayoutType.data?.allowedActions),
    [params.layoutTypeId, getLayoutType.data]
  )

  const onDeleteLayoutType =
    (layoutTypeId: LayoutTypeId) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        deleteLayoutType.mutate(layoutTypeId)
      }
    }

  const onCloneLayoutType =
    (layoutTypeId: LayoutTypeId) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        cloneLayoutType.mutate(layoutTypeId)
      }
    }

  const onRemoveHtmlContent = (confirmResult: boolean): void => {
    if (confirmResult) {
      setHtmlContentRemoved(true)
    }
  }

  return (
    <LayoutTypeFormStyled>
      <h1>{params.layoutTypeId ? t('pages.admin.layout_type.edit.title') : t('pages.admin.layout_type.new.title')}</h1>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" rowGap={2} pb={4}>
          {(isFormDisabled || isDeleteDisabled) && (
            <Alert severity="warning">
              {isFormDisabled
                ? t('pages.admin.layout_type.messages.edit_restricted')
                : t('pages.admin.layout_type.messages.delete_restricted')}
            </Alert>
          )}
          <FormTextFieldStyled
            form={form}
            name="buildingType"
            label={t('pages.admin.layout_type.form.field.building_type')}
            selectOptions={buildingTypeOptions}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            form={form}
            name="name"
            label={t('pages.admin.layout_type.form.field.name')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTranslatedTextField
            form={form}
            nameEng="title.eng"
            nameEst="title.est"
            nameRus="title.rus"
            label={t('pages.admin.layout_type.form.field.title')}
            disabled={isLoading || isFormDisabled}
          />
          {/*
          <FormTextField
            name={form.names.title.eng}
            label={`${t('pages.admin.layout_type.form.field.title')} ${t('app.languages.en')}`}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextField
            name={form.names.title.est}
            label={`${t('pages.admin.layout_type.form.field.title')} ${t('app.languages.et')}`}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextField
            name={form.names.title.rus}
            label={`${t('pages.admin.layout_type.form.field.title')} ${t('app.languages.ru')}`}
            disabled={isLoading || isFormDisabled}
          />
          */}
          <FormTranslatedTextField
            form={form}
            nameEng="description.eng"
            nameEst="description.est"
            nameRus="description.rus"
            label={t('pages.admin.layout_type.form.field.description')}
            disabled={isLoading || isFormDisabled}
            multiline
            rows={3}
          />
          {/*
          <FormTextField
            name={form.names.description.est}
            label={`${t('pages.admin.layout_type.form.field.description')} ${t('app.languages.et')}`}
            disabled={isLoading || isFormDisabled}
            multiline
            rows={3}
          />
          <FormTextField
            name={form.names.description.rus}
            label={`${t('pages.admin.layout_type.form.field.description')} ${t('app.languages.ru')}`}
            disabled={isLoading || isFormDisabled}
            multiline
            rows={3}
          />
          <FormTextField
            name={form.names.floorsCount}
            label={t('pages.admin.layout_type.form.field.floors_count')}
            type="number"
            disabled={isLoading || isFormDisabled}
          />
          */}
          {selectedBuildingType?.hasApartments && (
            <FormTextFieldStyled
              form={form}
              name="apartmentsCount"
              label={t('pages.admin.layout_type.form.field.apartments_count')}
              type="number"
              disabled={isLoading || isFormDisabled}
            />
          )}
          <FormTextFieldStyled
            form={form}
            name="virtualTourUrl"
            label={t('pages.admin.layout_type.form.field.virtual_tour_url')}
            disabled={isLoading || isFormDisabled}
          />
          <FormCheckbox1
            form={form}
            name="visibleOnLanding"
            label={t('pages.admin.layout_type.form.field.visible_on_landing')}
            disabled={isLoading || isFormDisabled}
          />

          <Box display="flex" alignItems="center" columnGap={2}>
            {htmlFileUrls.map((url, index) => (
              <>
                <Link key={`download-html-content-${index}`} href={url} target="_blank" rel="noopener" download>
                  {t('pages.admin.layout_type.html_content.buttons.download_html_content')}
                </Link>
                <ButtonWithConfirmation
                  key={`remove-html-content-${index}`}
                  color="error"
                  onConfirmResult={onRemoveHtmlContent}
                  disabled={isLoading || isFormDisabled}
                  confirmTitle={t('global.consent.delete.title')}
                  confirmMessage={t('global.consent.delete.message')}
                >
                  <Delete />
                </ButtonWithConfirmation>
              </>
            ))}
          </Box>
          {/*!isFormDisabled && (
          <Box display="flex" alignItems="center" columnGap={2}>
            <span>{t('pages.admin.layout_type.html_content.upload.title')}:</span>
            <FormFileInput name={form.names.htmlContent} />
          </Box>
          )*/}
          <Box display="flex" columnGap={2} mt={2}>
            {!isFormDisabled && (
              <Button type="submit" disabled={isLoading || isFormDisabled}>
                {t('global.buttons.submit')}
              </Button>
            )}
            {hasValue(params.layoutTypeId) && !isDeleteDisabled && (
              <ButtonWithConfirmation
                color="error"
                disabled={isLoading || isDeleteDisabled}
                onConfirmResult={onDeleteLayoutType(params.layoutTypeId)}
                confirmTitle={t('global.consent.delete.title')}
                confirmMessage={t('global.consent.delete.message')}
              >
                {t('global.buttons.delete')}
              </ButtonWithConfirmation>
            )}
            {hasValue(params.layoutTypeId) && (
              <ButtonWithConfirmation
                disabled={isLoading}
                onConfirmResult={onCloneLayoutType(params.layoutTypeId)}
                confirmTitle={t('global.consent.clone.title')}
                confirmMessage={t('global.consent.clone.message')}
              >
                {t('global.buttons.clone')}
              </ButtonWithConfirmation>
            )}
          </Box>
        </Box>
      </form>
      {getLayoutType.data && (
        <LayoutTypeFormContext.Provider value={{ layoutType: getLayoutType.data }}>
          <LayoutTypeFormTabs />
          <Outlet />
        </LayoutTypeFormContext.Provider>
      )}
    </LayoutTypeFormStyled>
  )
}
